import React, { useEffect, useState, useRef, useCallback } from "react";
import HomeIcon from "../../assets/images/sidenav/home";
import MenuIcon from "../../assets/images/sidenav/menu";
import ShareIcon from "../../assets/images/sidenav/share";
import AdminIcon from "../../assets/images/sidenav/admin";
import OptionsIcon from "../../assets/images/sidenav/options";
import LogoutIcon from "../../assets/images/sidenav/logout";
import TeamIcon from "../../assets/images/sidenav/team";
import EditProfileIcon from "../../assets/images/sidenav/profileEdit";
import lograLogo from "../../assets/images/general/logra-logo-circle.png";
import { useSelector } from "react-redux";
import { getCurrentCompany } from "../../utils/authUtils";
import {
  SidebarContainer,
  SidebarHeader,
  MenuItem,
  MenuList,
  ExpandButton,
  MenuLogoItem,
  SidebarBody,
  SidebarFooter,
  DropdownMenu,
  DropdownMenuItem,
  Credits,
  IconContainer,
  LogoContainer,
  LogoImage,
  MenuLogoContainer,
} from "./SidebarStyles";
import "./CustomSidebar.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteCookie } from "../../utils/cookiesUtils";
import { deactivateSession } from "../../features/user_session/userSessionSlice";

const CustomSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [institutionData, setInstitutionData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const corporation_data =
    useSelector((state) => state.corp_data.data) || getCurrentCompany();
  const user_session = useSelector((state) => state.user_session);
  const is_admin = user_session.is_admin;
  const is_premium = user_session.is_premium;
  const sidebarRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const corp_color = corporation_data.color;

  useEffect(() => {
    const current_company = getCurrentCompany();
    if (corporation_data) {
      setInstitutionData(corporation_data);
    } else {
      setInstitutionData(current_company);
    }

    document.addEventListener("click", handleOutSidebarClick);
    return () => {
      document.removeEventListener("click", handleOutSidebarClick);
    };
  }, []);

  const handleOutSidebarClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  const expandSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMenuItemSelect = (option) => {
    setSelectedOption(option);
  };
  const handleCloseDropdownMenu = (show) => {
    setShowDropDown(!showDropDown);
  };

  const handleDropdownMenu = (show) => {
    setShowDropDown(show);
  };

  const logOut = () => {
    dispatch(deactivateSession());
  };

  const navigateDashboard = useCallback(() => {
    history.push("/home");
  }, [history]);

  const navigateReferal = useCallback(() => {
    history.push("/referal");
  }, [history]);

  const navigateToAdmin = useCallback(() => {
    history.push("/admin/dashboard");
  }, [history]);

  const closeSession = () => {
    deleteCookie("userSession");
    deleteCookie("session_token");
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    logOut();
    history.push("/login");
  };

  const default_color = "#2c5999";

  return (
    <SidebarContainer isSidebarOpen={isSidebarOpen} ref={sidebarRef}>
      <SidebarHeader>
        <MenuList>
          <MenuLogoItem>
            <ExpandButton onClick={expandSidebar}>
              <div className={isSidebarOpen ? "expanded-logo" : "normal-logo"}>
                <MenuIcon color={corp_color ? corp_color : default_color} />
              </div>
            </ExpandButton>
          </MenuLogoItem>
        </MenuList>
      </SidebarHeader>
      <SidebarBody>
        <MenuList>
          <MenuLogoContainer>
            <LogoContainer
              colorSelected={corp_color ? corp_color : default_color}
              className={isSidebarOpen ? "expanded" : ""}
            >
              <LogoImage
                src={corporation_data.logo ? corporation_data.logo : lograLogo}
                className={isSidebarOpen ? "expanded" : ""}
                alt="logo"
              />
            </LogoContainer>
          </MenuLogoContainer>
          <MenuItem
            isSidebarOpen={isSidebarOpen}
            colorSelected={corp_color ? corp_color : default_color}
            onClick={() => {
              handleMenuItemSelect("Inicio");
              navigateDashboard();
            }}
            className={selectedOption === "Inicio" ? "selectedOption" : ""}
          >
            <IconContainer>
              <HomeIcon color={corp_color ? corp_color : default_color} />
            </IconContainer>
            <span>Inicio</span>
          </MenuItem>
          <MenuItem
            isSidebarOpen={isSidebarOpen}
            onClick={() => {
              handleMenuItemSelect("Compartir");
              navigateReferal();
            }}
            colorSelected={corp_color ? corp_color : default_color}
            className={selectedOption === "Compartir" ? "selectedOption" : ""}
          >
            <IconContainer>
              <ShareIcon color={corp_color ? corp_color : default_color} />
            </IconContainer>
            <span>Compartir</span>
          </MenuItem>
          {is_admin ? (
            <React.Fragment>
              <MenuItem
                isSidebarOpen={isSidebarOpen}
                onClick={() => {
                  handleMenuItemSelect("Administrar");
                  navigateToAdmin();
                }}
                colorSelected={corp_color ? corp_color : default_color}
                className={
                  selectedOption === "Administrar" ? "selectedOption" : ""
                }
              >
                <IconContainer>
                  <AdminIcon color={corp_color ? corp_color : default_color} />
                </IconContainer>
                <span>Administar</span>
              </MenuItem>
            </React.Fragment>
          ) : null}
        </MenuList>
      </SidebarBody>
      <SidebarFooter>
        <MenuList>
          <MenuItem
            isSidebarOpen={isSidebarOpen}
            colorSelected={corp_color ? corp_color : default_color}
            onClick={() => {
              handleMenuItemSelect("Mas opciones");
              handleCloseDropdownMenu();
            }}
            onMouseEnter={() => handleDropdownMenu(true)}
            onMouseLeave={() => handleDropdownMenu(false)}
            style={{ marginBottom: "20px" }}
          >
            <IconContainer>
              <OptionsIcon color={corp_color ? corp_color : default_color} />
            </IconContainer>
            <span>Mas opciones</span>
            {showDropDown && (
              <DropdownMenu isSidebarOpen={isSidebarOpen}>
                <DropdownMenuItem
                  onClick={() => {
                    history.push("/configuration/edit_profile");
                  }}
                >
                  <EditProfileIcon
                    color={corp_color ? corp_color : default_color}
                  />
                  Editar perfil
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    history.push("/my-team");
                  }}
                >
                  <a>
                    <TeamIcon color={corp_color ? corp_color : default_color} />
                  </a>
                  <p>Gestionar miembros del equipo</p>
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </MenuItem>
          <MenuItem
            isSidebarOpen={isSidebarOpen}
            onClick={() => {
              closeSession();
            }}
            colorSelected={corp_color ? corp_color : default_color}
          >
            <IconContainer>
              <LogoutIcon color={corp_color ? corp_color : default_color} />
            </IconContainer>
            <span>Cerrar sesión</span>
          </MenuItem>
          <Credits
            colorSelected={corp_color ? corp_color : default_color}
            isSidebarOpen={isSidebarOpen}
          >
            Powered by LOGRA
          </Credits>
        </MenuList>
      </SidebarFooter>
    </SidebarContainer>
  );
};

export default CustomSidebar;
